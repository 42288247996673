import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  InputGroup,
  Dropdown,
  FormCheck,
  Modal,
} from "react-bootstrap";
import Search from "../../../assests/images/dashborad/Search.png";
import columnHideshow from "../../../assests/images/dashborad/table.svg";
import PDFIcon from "../../../assests/images/dashborad/Group.svg";
import PaginationSection from "../../../components/PaginationSection";
import { useDebounce } from "../../../hooks/debounce";
import { doctorService } from "../../../services/doctor.service";
import { patientService } from "../../../services/patient.service";
import LoaderSpinner from "../../../components/spinner";
import ListingDropdown from "../../../components/listingDropdown";
import ModalComponent from "../../../components/modal";
import { Store } from "../../../store/Store";
import { roles } from "../../../constants/common.constants";
import { Type } from "../../../constants/storeAction.constants";
import { generateDoctorProfileDetailsInitialValue, formatDateToCustomFormatWithOffset } from "../../../utils/helperFunction";
import DetailsPopUp from "../../../components/detailsPopUp";
import apiURL from "../../../constants/apiURL";
import { useNavigate } from "react-router-dom";

const posture_scores = [
  { name: "85-100 excellent Chance of injury is unlikely", value: "85-100" },
  { name: "75-84 Good Chance of injury is minimal", value: "75-84" },
  { name: "65-74 fair Chance of injury is moderate", value: "65-74" },
  { name: "<65 poor Chance of injury is high", value: "0-64" },
];

function PatientAssessments({
  organization_id = "",
  clinic_id = "",
  doctor_id = "",
  patient_id = "",
}) {

  const navigate = useNavigate();

  const location = useLocation();
  const patientId = location.state; // Here is your passed data
  const { state, dispatch } = useContext(Store);
  const { userInfo, editDoctorDetails } = state;
  const { user_type, id, user_id } = userInfo;
  const initialClinicId = user_type === roles.clinic ? id : clinic_id;
  const [selectedDoctor, setSelectedDoctor] = useState(doctor_id);
  const [show, setShow] = useState("");
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(patientId);
  const [doctors, setDoctors] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingClinic, setLoadingClinic] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedAssessments, setCheckedAssessments] = useState([]);
  const debouncedSearchTerm = useDebounce(search, 600);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedPostureScore, setSelectedPostureScore] = useState("");
  const [assessmentType, setassessmentType] = useState("");
  const [assessmentList, setassessmentList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPatientViewId, setcurrentPatientViewId] = useState(null);
  const [pdfOrView, setPdfOrView] = useState(null);
  const [columns, setColumns] = useState({
    patient: true,
    patient_id: true,
    doctor: true,
    assessment_id: true,
    assessment_type: true,
    date_of_assessment: true,
    posture_score: false,
    // mobility_score: false,
    // pain_score: false,
  });

  const handleShowDetailsClose = () => setShowDetails(false);

  //   To fetch Total assessment for the table list
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const initialOrganisationId =
          user_type === roles.organization ? id : organization_id;
        const initialDoctorId =
          user_type === roles.doctor ? id : doctor_id;
        let params = {
          organization_id: initialOrganisationId,
          clinic_id: initialClinicId,
          assessments_id: debouncedSearchTerm,
          posture_score: selectedPostureScore,
          assessments_type: assessmentType,
          initial_doctor_id: initialDoctorId,
          page: currentPage,
          // patient_id: selectedPatient,
        };
        if (selectedDoctor) {
          params.doctor_id = JSON.stringify([selectedDoctor]);
        }
        if (selectedPatient) {
          params.patient_id = JSON.stringify([selectedPatient]);
        }
        if (user_type ===  roles.doctor) {
          params.doctor_id = JSON.stringify([initialDoctorId]);
          params.assessment_type =  roles.doctor;
          params.count = roles.doctor;
        }
        const { count, result } = await patientService.getAssessmentSummary(
          params
        );
        setTotalItems(count);
        console.log(result);
        setAssessments(result);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    fetchData();
  }, [
    debouncedSearchTerm,
    selectedPatient,
    currentPage,
    organization_id,
    user_type,
    id,
    selectedDoctor,
    selectedPostureScore,
    assessmentType,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await patientService.getAssessmentColumn();
        setColumns(result.data.assessment_column_filter)
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  // const createaAssessmentFilter = async () => {
  //     try {
  //       const payload = {
  //         user : user_id,
  //         assessment_column_filter : {
  //           patient: true,
  //           mrn: true,
  //           doctor: true,
  //           assessment_id: true,
  //           assessment_type: true,
  //           date_of_assessment: true,
  //           posture_score: false,
  //           mobility_score: false,
  //           pain_score: false,
  //         }
  //       }
  //       setLoading(true);
  //       const result = await patientService.createAssessmentColumn(payload);
  //       if(result){
  //         setColumns(result.data.assessment_column_filter)
  //       }
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err);
  //     }
  // };


  // To fetch doctor list for doctor filter
  useEffect(() => {
    const fetchData = async () => {
      try {
        const organization_id = user_type === roles.organization ? id : "";
        const clinic_id = user_type === roles.clinic ? id : "";
        const doctor_id = user_type === roles.doctor_id ? id : "";

        const { data: doctors } = await doctorService.getDoctorNameId({
          organization_id: organization_id,
          clinic_id: clinic_id,
          doctor_id: doctor_id
        });
        setDoctors(doctors);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, user_type]);

  // To fetch doctor list for doctor filter
  useEffect(() => {
    const fetchAssessmentType = async () => {
      try {
        setLoadingClinic(true);
        const organization_id = user_type === roles.organization ? id : "";
        const clinic_id = user_type === roles.clinic ? id : "";
        const doctor_id = user_type === roles.doctor_id ? id : "";

        const result = await patientService.assessmentTypeList({
          organization_id: organization_id,
          clinic_id: clinic_id,
          doctor_id: doctor_id
        });
        if (result) {
          const updatedResult = result.map(({ id, ...rest }) => rest);
          setassessmentList(updatedResult);
        }
        setLoadingClinic(false);
      } catch (err) {
        console.log(err);
        setLoadingClinic(false);
      }
    };
    fetchAssessmentType();
  }, [id, user_type]);

  // To fetch patient list for patient filter
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setLoadingClinic(true);
        const clinic_id = user_type === roles.clinic ? id : "";
        const results = await patientService.getPatientsNameId({
          clinic_id: clinic_id,
        });
        setPatients(results);
        setLoadingClinic(false);
      } catch (err) {
        setLoadingClinic(false);
        console.log(err);
      }
    };
    fetchPatients();
  }, [id, user_type]);

  const getPatientFilter = useCallback(() => {
    if (selectedPatient) {
      return patients?.find(
        (patient) => patient?.patient_id === selectedPatient
      )?.name;
    } else {
      return "All";
    }
  }, [patients, selectedPatient]);

  const getDoctorFilter = useCallback(() => {
    if (selectedDoctor) {
      return doctors?.find((doctor) => doctor?.doctor_id === selectedDoctor)
        ?.name;
    } else {
      return "All";
    }
  }, [doctors, selectedDoctor]);

  const getPostureFilter = useCallback(() => {
    if (selectedPostureScore) {
      return posture_scores?.find(
        (score) => score?.value === selectedPostureScore
      )?.name;
    } else {
      return "All";
    }
  }, [selectedPostureScore]);

  const getAssessmentTypeFilter = useCallback(() => {
    if (assessmentType) {
      return assessmentList?.find((score) => score?.value === assessmentType)
        ?.name;
    } else {
      return "All";
    }
  }, [assessmentType, assessmentList]);

  const filterHandle = useCallback((slug, value) => {
    setCurrentPage(1);
    if (slug === "Patient") {
      setSelectedPatient(value);
    }
    if (slug === "Doctor") {
      setSelectedDoctor(value);
    }
    if (slug === "Align Score") {
      setSelectedPostureScore(value);
    }
    if (slug === "search") {
      setSearch(value);
    }
    if (slug === "Assessment Type") {
      setassessmentType(value);
    }
  }, []);

  const showPatientDetails = async (id) => {
    const allowedUserTypes = [roles.organization, roles.admin, roles.superAdmin, roles.clinic, roles.doctor];

    if (allowedUserTypes.includes(userInfo.user_type)) {
      try {
        setLoading(true);
        const data = await patientService.getPatientDetails(id);

        setLoading(false);
        setShowDetails(true);
        dispatch({
          type: Type.ADD_EDIT_DOCTOR_STEP_1,
          payload: generateDoctorProfileDetailsInitialValue(data),
        });
        dispatch({ type: Type.EDIT_DOCTOR_DETAILS, payload: data });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const downloadData = () => {
    const downloadCsv = async () => {
      try {
        setLoadingClinic(true);
        let params = {};
        const clinic_id = user_type === roles.clinic ? id : "";
        const doctor_id = user_type === roles.doctor ? id : "";
        params.clinic_id = clinic_id;
        params.doctor_id = doctor_id;
        if (checkedAssessments.length > 0) {
          params.assessments_id_list = JSON.stringify(checkedAssessments);
        }

        const response = await patientService.getAssessmentCSV(params);
        setLoadingClinic(false);

        // Ensure the response contains the CSV dataS
        if (response) {
          // Create a Blob from the CSV String
          const blob = new Blob([response], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          // Create a link and set the URL as the href
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "assessment_data.csv"); // Set the file name for download

          // Append to the document and trigger the download
          document.body.appendChild(link);
          link.click();

          // Clean up by removing the link and revoking the URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      } catch (err) {
        setLoadingClinic(false);
        console.error(err);
      }
    };

    downloadCsv(); // Call the async function
  };


  const exportData = () => {
    const downloadData = async () => {
      try {
        setLoadingClinic(true);

        const response = await fetch('https://stagebackend.metadoc.ai/clinic/detailed-csv/', {
          method: 'GET',
          headers: {
            'Cookie': 'csrftoken=5UmyOmTBb3apzQoFqpeJoqlatktC8HVa'
          }
        });

        const result = await response.json();

        if (result.status_code === 200 && result.data && result.data.url) {
          window.open(result.data.url, '_blank');
        } else {
          console.error('Error: ', result.message);
        }
      } catch (err) {
        console.error('Fetch error: ', err);
      } finally {
        setLoadingClinic(false);
      }
    };

    downloadData(); // Call the async function
  };
  const isPopUP = organization_id || clinic_id;

  const className = isPopUP && show ? "make_display_none" : "Patients_section";

  const patientFilter = useMemo(() => {
    if (user_type !== roles.doctor) {
      return (
        <Col md={3} className="status_dropdown enable-status">
          <ListingDropdown
            getFilterLabel={getPatientFilter}
            filterHandle={filterHandle}
            values={patients}
            id="patient_id"
            filterName="Patient"
          />
        </Col>
      );
    }
  }, [patients, filterHandle, getPatientFilter]);

  const doctorFilter = useMemo(() => {
    if (user_type !== roles.doctor) {
      return (
        <Col md={3} className="status_dropdown enable-status">
          <ListingDropdown
            getFilterLabel={getDoctorFilter}
            filterHandle={filterHandle}
            values={doctors}
            id="doctor_id"
            filterName="Doctor"
          />
        </Col>
      );
    }
    return null;
  }, [user_type, doctors, filterHandle, getDoctorFilter]);

  const postureFilter = useMemo(() => {
    return (
      <Col md={3} className="status_dropdown enable-status">
        <ListingDropdown
          getFilterLabel={getPostureFilter}
          filterHandle={filterHandle}
          values={posture_scores}
          id="value"
          filterName="Align Score"
        />
      </Col>
    );
  }, [filterHandle, getPostureFilter]);

  const asessmentTypeFilter = useMemo(() => {
    if (user_type !== roles.doctor) {
    return (
      <Col md={3} className="status_dropdown enable-status">
        <ListingDropdown
          getFilterLabel={getAssessmentTypeFilter}
          filterHandle={filterHandle}
          values={assessmentList}
          id="value"
          filterName="Assessment Type"
        />
      </Col>
    );
   }
  }, [filterHandle, getAssessmentTypeFilter, assessmentList]);

  // Handle the checkbox state change
  const handleCheckboxChangeCol = (columnName) => {
    setColumns((prevColumns) => {
      const updatedColumns = {
        ...prevColumns,
        [columnName]: !prevColumns[columnName],
      };
      const payload = {
        user: user_id,
        assessment_column_filter: updatedColumns
      };
      updateAssessmentColumn(payload);

      return updatedColumns;
    });
  };

  const updateAssessmentColumn = async (payload) => {
    try {
      setLoading(true);
      const result = await patientService.updateAssessmentColumn(payload);
      if (result) {
        setColumns(result.data.assessment_column_filter)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // Handle change for header checkbox
  const handleHeaderCheckboxChange = () => {
    if (checkedAssessments.length === assessments.length) {
      setCheckedAssessments([]);
    } else {
      setCheckedAssessments(
        assessments.map((assessment) => assessment.assessment_id)
      );
    }
  };

  // Handle change for row checkboxes
  const handleRowCheckboxChange = (assessmentId) => {
    if (checkedAssessments.includes(assessmentId)) {
      setCheckedAssessments(
        checkedAssessments.filter((id) => id !== assessmentId)
      );
    } else {
      setCheckedAssessments([...checkedAssessments, assessmentId]);
    }
  };

  useEffect(() => {
    if (currentPatientViewId !== null && pdfOrView !== "") {
      downloadPDF();
    }
  }, [currentPatientViewId, pdfOrView]);

  const handlePdfView = (id, action) => {
    setcurrentPatientViewId(id);
    setPdfOrView(action);
        // setShowModal(true);
  };
  const downloadPDF = (isDoctor = false) => {
    const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    const encodedTimeZone = encodeURIComponent(user_timezone);
    if (pdfOrView === "forPDF") {
      if (currentPatientViewId === null) {
        console.error("No ID set for downloading PDF.");
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}${apiURL.PATIENT_PDF}${currentPatientViewId}/?timezone=${encodedTimeZone}`;
      if (isDoctor)
        url = `${process.env.REACT_APP_API_URL}${apiURL.PATIENT_PDF}${currentPatientViewId}/?template_type=doctorPdf&timezone=${encodedTimeZone}`;

      window.open(url, "_blank");
    }
    if (pdfOrView === "forVIEW") {
      if (currentPatientViewId === null) {
        console.error("No ID set for downloading PDF.");
        return;
      }
      
      let url = `${process.env.REACT_APP_API_URL}${apiURL.PATIENT_PDF}${currentPatientViewId}/?view_type=html&timezone=${encodedTimeZone}`;
      if (isDoctor)
        url = `${process.env.REACT_APP_API_URL}${apiURL.PATIENT_PDF}${currentPatientViewId}/?template_type=doctor&view_type=html&timezone=${encodedTimeZone}`;

      window.open(url, "_blank");
    }
  };

  const onCompareClick = ({ patient_id, scan_id }) => {
    navigate(`/compare2/${patient_id}/${scan_id}`);
  }
  return (
    <>
      <div className={className}>
        <div>
          <div className="d-inline-block">
            <h1>Assessments ({totalItems})</h1>
          </div>
          <div className="right-header">
            <LoaderSpinner loading={loading || loadingClinic} />
            <div className="position-relative">
              <img className="search-img" src={Search} alt="search" />
              <input
                value={search}
                onChange={(e) => filterHandle("search", e.target.value)}
                className=" search-input"
                placeholder="Search by Assessment ID"
              />
            </div>
            <div>
              <button
                onClick={downloadData}
                className="btn export-button w-export"
              >
                Export
              </button>
            </div>
          </div>
        </div>

        <Row className="mt-4 relative-position">
          {doctorFilter}
          {patientFilter}
          {asessmentTypeFilter}
          {postureFilter}
          {/* <Col md={2} className="absolute-position">
            <button
              onClick={exportData}
              className="btn export-button w-export"
            >
              Export Data
            </button>
          </Col> */}
        </Row>
        <Row>
          <Col md={12} className="mt-4">
            <div className="Patienttable">
              <Table
                responsive
                className={`table-stripednew Patients-table `}
                variant="dark"
              >
                <thead>
                  <tr>
                    <th>
                      <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                          aria-label="Checkbox for following text input"
                          checked={
                            checkedAssessments.length === assessments.length &&
                            assessments.length > 0
                          }
                          onChange={handleHeaderCheckboxChange}
                        />
                      </InputGroup>
                    </th>
                    {columns.patient && <th>Patient</th>}
                    {columns.patient_id && <th>Patient ID</th>}
                    {columns.doctor && <th>Doctor</th>}
                    {columns.assessment_id && <th>Assessment ID</th>}
                    {columns.assessment_type && <th>Assessment Type</th>}
                    {columns.date_of_assessment && <th>Date of Assessment</th>}
                    {columns.posture_score && <th>Align Score</th>}
                    {/* {columns.mobility_score && <th>Mobility Score</th>}
                    {columns.pain_score && <th>Pain Score</th>} */}
                    <th>
                      <Dropdown>
                        <Dropdown.Toggle variant="dark">
                          <img
                            className="columnHideshow-img"
                            src={columnHideshow}
                            alt="columnHideshow"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dark-dropdown-menu">
                          {Object.keys(columns).map((key) => (
                            <Dropdown.Item key={key} as="div">
                              <InputGroup>
                                <FormCheck
                                  checked={columns[key]}
                                  onChange={() => handleCheckboxChangeCol(key)}
                                  label={
                                    <span className="checkbox-label">
                                      {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </span>
                                  }
                                  className="square-checkbox"
                                />
                              </InputGroup>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assessments?.map((assessment) => (
                    <tr key={assessment?.assessment_id}>
                      <td>
                        <InputGroup className="mb-3">
                          <InputGroup.Checkbox
                            aria-label="Checkbox for following text input"
                            checked={checkedAssessments.includes(
                              assessment.assessment_id
                            )}
                            onChange={() =>
                              handleRowCheckboxChange(assessment.assessment_id)
                            }
                          />
                        </InputGroup>
                      </td>
                      {columns.patient && (
                        <td
                          className="name-text"
                          onClick={() =>
                            showPatientDetails(assessment?.patient_id)
                          }
                        >
                          {assessment?.patient}
                        </td>
                      )}
                      {columns.patient_id && <td>{assessment?.mrn}</td>}
                      {columns.doctor && <td>{assessment?.doctor}</td>}
                      {columns.assessment_id && (
                        <td>{assessment?.assessment_id}</td>
                      )}
                      {columns.assessment_type && (
                        <td>{assessment?.assessment_type}</td>
                      )}
                      {columns.date_of_assessment && (
                        <td>
                          {assessment.assessment_created ? formatDateToCustomFormatWithOffset(assessment.assessment_created) : ''}
                        </td>
                        // <td>{assessment?.assessment_created}</td>
                      )}
                      {columns.posture_score && (
                        <td>{assessment?.posture_score}</td>
                      )}
                      {/* {columns.mobility_score && <td>
                        {assessment?.posture_score}
                      </td>}
                      {columns.pain_score && (
                        <td>{assessment?.posture_score}</td>
                      )} */}
                      <td>
                        {/* <span className="name-text me-4" onClick={() => {
                          handlePdfView(assessment?.scan_id, "forVIEW");
                          // setPdfOrView("forVIEW");
                        }}>View</span> */}
                        <span>
                          {" "}
                          <img
                            className="PDFIcon-img"
                            src={PDFIcon}
                            alt="PDFIcon"
                            onClick={() => {
                              handlePdfView(assessment?.scan_id, "forPDF"); // setPdfOrView("forPDF"); 
                            }}
                          />{" "}
                        </span>
                      </td>
                      {user_type !== roles.doctor && (
                        <td>
                          <button
                            onClick={() => onCompareClick(assessment)}
                            className="btn export-button w-export"
                          >
                            Compare
                          </button>

                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <PaginationSection
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              count={totalItems}
            />
          </Col>
        </Row>
      </div>
      <ModalComponent
        show={showModal}
        setShow={setShowModal}
        modelTitle={pdfOrView === "forVIEW" ? "View Template" : "Export PDF"}
        centered
      >
        {/* Content inside the modal */}
        <Modal.Body>
          <div className="export-text">{pdfOrView === "forVIEW" ? "Do you want to View" : "Do you want to Export"}</div>
          <Row>
            <Col md={5} className="mt-4">
              <button
                onClick={() => downloadPDF(true)}
                className="btn export-button w-export"
              >
                Doctor View
              </button>
            </Col>
            <Col
              md={2}
              className="mt-4 d-flex justify-content-center align-items-center"
            >
              <p className="export-text mt-2">OR</p>
            </Col>
            <Col md={5} className="mt-4">
              <button
                onClick={() => downloadPDF(false)}
                className="btn export-button w-export"
              >
                Patient View
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </ModalComponent>
      <DetailsPopUp
        show={showDetails}
        handleClose={handleShowDetailsClose}
        details={editDoctorDetails}
        faxKey={"doctor_fax"}
        // handleEdit={() => navigate(URL.DOCTOR.EDIT.PROFILE_DETAIL)}
        // handleDelete={handleDeleteDoctor}
        hideFooter={true}
      />
    </>
  );
}

export default PatientAssessments;
